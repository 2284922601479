import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Link } from 'docz';
import { Pagination } from '@entur/menu';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { Paragraph, Link as LinkText } from '@entur/typography';
import { Table, TableHead, TableBody, TableFooter, TableRow, HeaderCell, DataCell, useSortableData } from '@entur/table';
import { ImportStatement } from '~/components/ImportStatement';
import testdata from '../layout-og-flater/testdata';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [currentPage, setPage] = React.useState(1)\n  const [results, setResults] = React.useState(10)\n  const numberOfResults = 100\n  return (\n    <Pagination\n      pageCount={Math.round(numberOfResults / results)}\n      numberOfResults={numberOfResults}\n      currentPage={currentPage}\n      onPageChange={page => setPage(page)}\n      resultsPerPage={results}\n      onResultsPerPageChange={e => setResults(e)}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      Pagination,
      Playground,
      PageHeader,
      Paragraph,
      LinkText,
      Table,
      TableHead,
      TableBody,
      TableFooter,
      TableRow,
      HeaderCell,
      DataCell,
      useSortableData,
      ImportStatement,
      testdata,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [currentPage, setPage] = React.useState(1);
        const [results, setResults] = React.useState(10);
        const numberOfResults = 100;
        return <Pagination pageCount={Math.round(numberOfResults / results)} numberOfResults={numberOfResults} currentPage={currentPage} onPageChange={page => setPage(page)} resultsPerPage={results} onResultsPerPageChange={e => setResults(e)} mdxType="Pagination" />;
      }}
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "pagination"
    }}>{`Pagination`}</h3>
    <ImportStatement imports="Pagination" mdxType="ImportStatement" />
    <Props of={Pagination} mdxType="Props" />
    <p><inlineCode parentName="p">{`<Pagination />`}</inlineCode>{`-komponenten er ganske grei å bruke. Du sier hvor mange sider du har totalt, hvilken side man er på nå, og hva som skal skje når man trykker på et tall.`}</p>
    <p>{`Hver gang brukeren ber om å endre en side, så blir callbacken `}<inlineCode parentName="p">{`onPageChange`}</inlineCode>{` kalt med nummeret til den ønskede siden. Det er da opp til utvikleren å implementere navigasjonen derifra - f.eks. med `}<inlineCode parentName="p">{`window.history.push()`}</inlineCode>{`, eller APIene til `}<inlineCode parentName="p">{`react-router`}</inlineCode>{`, om du bruker det.`}</p>
    <p>{`Du kan også spesifisere `}<inlineCode parentName="p">{`showInput`}</inlineCode>{` for å vise et input-felt, der man kan skrive inn hvilken side man ønsker å bruke. Det kan være praktisk i svært store
datasett, men bør kun brukes der det er behov.`}</p>
    <p>{`Om du ønsker flerspråklig støtte, har du tilgang på flere props: `}<inlineCode parentName="p">{`pageLabel`}</inlineCode>{`, `}<inlineCode parentName="p">{`previousPageLabel`}</inlineCode>{`, `}<inlineCode parentName="p">{`nextPageLabel`}</inlineCode>{`, og eventuelt `}<inlineCode parentName="p">{`inputLabel`}</inlineCode>{` om det trengs.`}</p>
    <p>{`For visning av resultat per side, og muligheten for å endre dette, bruk `}<inlineCode parentName="p">{`resultsPerPage`}</inlineCode>{` og `}<inlineCode parentName="p">{`onResultsPerPageChange`}</inlineCode>{`.
Bruk så enten 10, 25 eller 50 resultater per side.`}</p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <Paragraph mdxType="Paragraph">
  Pagination brukes for å fordele innhold på over flere sider, for å unngå for
  mange elementer i én og samme visning. Komponenten brukes for eksempel i{' '}
  <LinkText as={Link} to="/komponenter/layout-og-flater/table" mdxType="LinkText">
    Tabeller
  </LinkText>{' '}
  der man presenterer et sett antall rader, med mulighet til å navigere seg til
  et annet sett.
    </Paragraph>
    <p>{`Pagineringskontroller er tilgjengelig for å gi rask tilgang til alle sider og indikerer at flere sider eksisterer.`}</p>
    <p><strong parentName="p">{`Kontrollene som støttes er:`}</strong></p>
    <ul>
      <li parentName="ul">{`Rader per side: Viser antall resultater slik at brukeren kan tilpasse hvor mye de ønsker å se.`}</li>
      <li parentName="ul">{`Venstre og høyre pil: Lar brukeren navigere til neste eller forrige side.`}</li>
      <li parentName="ul">{`Gå til side: Lar brukeren hoppe direkte til en ønsket side.`}</li>
    </ul>
    <h2 {...{
      "id": "eksempel"
    }}>{`Eksempel`}</h2>
    <Playground __position={4} __code={'() => {\n  const [currentPage, setPage] = React.useState(1)\n  const [results, setResults] = React.useState(10)\n  const numberOfResults = testdata.length\n  const pageCount = Math.ceil(numberOfResults / results)\n  return (\n    <>\n      <Pagination\n        pageCount={pageCount}\n        currentPage={currentPage}\n        onPageChange={page => setPage(page)}\n        resultsPerPage={results}\n        numberOfResults={numberOfResults}\n        onResultsPerPageChange={e => setResults(e)}\n      />\n      <Table fixed style={{ margin: \'1.5rem 0\' }}>\n        <TableHead>\n          <TableRow>\n            <HeaderCell>Navn</HeaderCell>\n            <HeaderCell>Publisert</HeaderCell>\n            <HeaderCell>Kategori</HeaderCell>\n            <HeaderCell>Mottaker</HeaderCell>\n          </TableRow>\n        </TableHead>\n        <TableBody>\n          {testdata\n            .filter(\n              (item, index) =>\n                index + 1 >= (currentPage - 1) * results + 1 &&\n                index + 1 <= currentPage * results,\n            )\n            .map((item, index) => (\n              <TableRow key={index}>\n                <DataCell>{item.name}</DataCell>\n                <DataCell>{item.date}</DataCell>\n                <DataCell>{item.category}</DataCell>\n                <DataCell>{item.receiver}</DataCell>\n              </TableRow>\n            ))}\n        </TableBody>\n      </Table>\n      <Pagination\n        pageCount={Math.ceil(numberOfResults / results)}\n        currentPage={currentPage}\n        onPageChange={page => setPage(page)}\n        numberOfResults={numberOfResults}\n        resultsPerPage={results}\n        onResultsPerPageChange={e => setResults(e)}\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      Pagination,
      Playground,
      PageHeader,
      Paragraph,
      LinkText,
      Table,
      TableHead,
      TableBody,
      TableFooter,
      TableRow,
      HeaderCell,
      DataCell,
      useSortableData,
      ImportStatement,
      testdata,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [currentPage, setPage] = React.useState(1);
        const [results, setResults] = React.useState(10);
        const numberOfResults = testdata.length;
        const pageCount = Math.ceil(numberOfResults / results);
        return <>
        <Pagination pageCount={pageCount} currentPage={currentPage} onPageChange={page => setPage(page)} resultsPerPage={results} numberOfResults={numberOfResults} onResultsPerPageChange={e => setResults(e)} mdxType="Pagination" />
        <Table fixed style={{
            margin: '1.5rem 0'
          }} mdxType="Table">
          <TableHead mdxType="TableHead">
            <TableRow mdxType="TableRow">
              <HeaderCell mdxType="HeaderCell">Navn</HeaderCell>
              <HeaderCell mdxType="HeaderCell">Publisert</HeaderCell>
              <HeaderCell mdxType="HeaderCell">Kategori</HeaderCell>
              <HeaderCell mdxType="HeaderCell">Mottaker</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody mdxType="TableBody">
            {testdata.filter((item, index) => index + 1 >= (currentPage - 1) * results + 1 && index + 1 <= currentPage * results).map((item, index) => <TableRow key={index} mdxType="TableRow">
                  <DataCell mdxType="DataCell">{item.name}</DataCell>
                  <DataCell mdxType="DataCell">{item.date}</DataCell>
                  <DataCell mdxType="DataCell">{item.category}</DataCell>
                  <DataCell mdxType="DataCell">{item.receiver}</DataCell>
                </TableRow>)}
          </TableBody>
        </Table>
        <Pagination pageCount={Math.ceil(numberOfResults / results)} currentPage={currentPage} onPageChange={page => setPage(page)} numberOfResults={numberOfResults} resultsPerPage={results} onResultsPerPageChange={e => setResults(e)} mdxType="Pagination" />
      </>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      